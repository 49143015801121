/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import facebookIcon from 'Style/icons/logos/facebook.svg';
import linkedinIcon from 'Style/icons/logos/linkedIn.svg';
import twitterIcon from 'Style/icons/logos/twitter.svg';

export const RENDER_NEWSLETTER = 'render_newsletter';

export const NEWSLETTER_COLUMN = {
    title: __('Newsletter'),
    columnActiveKey: 'newsletterActive',
    items: [
        {
            render: RENDER_NEWSLETTER,
        },
    ],
};

export const COLUMN_MAP = [
    {
        title: __('Quick Links'),
        items: [
            {
                href: '/about-us',
                title: __('About Us'),
            },
            {
                href: '/contact',
                title: __('Contact Us'),
            },
            {
                href: '/customer/account',
                title: __('My Account'),
            },
            {
                href: '/sales/order/history',
                title: __('Order History'),
            },
            {
                href: '/privacy-policy-cookie-restriction-mode',
                title: __('Privacy Policy'),
            },
            {
                href: '/returns-exchange-policy',
                title: __('Returns & Exchange Policy'),
            },
            {
                href: '/faqs',
                title: __('GCC Forms'),
            },
        ],
    },
    {
        title: __('Popular'),
        items: [
            {
                href: '/disposables',
                title: __('Disposable Vapes'),
            },
            {
                href: '/e-liquids',
                title: __('E-Liquids'),
            },
            {
                href: '/starter-kits',
                title: __('Vape Starter Kits'),
            },
            {
                href: '/clearance-vape-kits',
                title: __('Vape Clearance'),
            },
            {
                href: '/new-arrivals',
                title: __('New Arrivals'),
            },
            // {
            //     href: '/brands',
            //     title: __('Vape Wholesale by Brand'),
            // },
            {
                href: '/cbd',
                title: __('CBD Wholesale'),
            },
            {
                href: '/brands',
                title: __('Vape Wholesale by Brand'),
            },
        ],
    },
];
