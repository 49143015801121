/* eslint-disable react/jsx-no-undef */
import ContentWrapper from '@scandipwa/scandipwa/src/component/ContentWrapper';

import {FooterComponentProps, FooterRenderColumn, FooterRenderColumnItem} from 'Component/Footer/Footer.type';
import ImageComponent from 'Component/Image/Image.component';
import Logo from 'Component/Logo';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import {
    FooterComponent as SourceFooterComponent,
} from 'SourceComponent/Footer/Footer.component';
import {ReactElement} from 'Type/Common.type';
import media from 'Util/Media';
import {LOGO_MEDIA} from 'Util/Media/Media';

import './Footer.override.style';
import {COLUMN_MAP} from "./Footer.config";
import Image from "Component/Image";
import Link from "Component/Link";

/** @namespace VusaApp/Component/Footer/Component */
export class FooterComponent extends SourceFooterComponent {
    static defaultProps: Partial<FooterComponentProps> = {
        logo_alt: 'Vivo logo',
        header_logo_src: '',
    };


    renderColumnItemContent(src?: string, title?: string): ReactElement {
        if (!src) {
            return title;
        }

        return (
            <Image
                mix={ { block: 'Footer', elem: 'ColumnItemImage' } }
                src={ src }
            />
        );
    }

    renderColumnItemLink(
        item: FooterRenderColumnItem,
        i: number,
    ): ReactElement {
        const { onItemClick } = this.props;
        const { href = '/', src, title } = item;

        return (
            <Link
                block="Footer"
                elem="ColumnItem"
                to={ href }
                mods={ src ? { type: 'image' } : undefined }
                key={ i }
                aria-label={ title }
                onClick={ onItemClick }
            >
                { this.renderColumnItemContent(src, title) }
            </Link>
        );
    }

    renderColumnItem(item: FooterRenderColumnItem, i: number): ReactElement {
        const { render } = item;

        if (render && render in this.renderMap) {
            return this.renderMap[render as keyof typeof this.renderMap].render();
        }

        return (
            <li key={ "footer-column-item" + i }>
                { this.renderColumnItemLink(item, i) }
            </li>
        );
    }

    renderColumn(column: FooterRenderColumn, i?: number): ReactElement {
        const {
            title,
            columnActiveKey,
            items,
        } = column;


        if (columnActiveKey && !(columnActiveKey in this.props)) {
            return null;
        }

        return (
            <div className={"div" + ((i || 0) + 2)}  key={ "footer-" + i }>
                <li className="headline" key={ "footer-title-" + i }>{ title }</li>
                { items.map(this.renderColumnItem.bind(this)) }
            </div>
        );
    }

    renderFooter(): ReactElement {
        const {
            header_logo_src,
            logo_alt,
        } = this.props;

        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        return (
            <ContentWrapper
                mix={{block: 'Footer', elem: 'FooterLinksWrapper'}}
                wrapperMix={{block: 'Footer', elem: 'FooterLinksContent'}}
                label=""
            >
                <div block="parent">

                    <div block="div1">
                        <Logo
                            src={logoSrc}
                            alt={logo_alt}
                            title={logo_alt}
                        />
                        <li>
                            <i block="material-icons">location_on</i>
                            Las Vegas, NV 89118
                        </li>
                        <li block="intend">United States.</li>
                        <li>
                            <i block="material-icons">calendar_month</i>
                            Monday - Friday, 9am - 6pm PST
                        </li>
                        <li>
                            <i block="material-icons">call</i>
                            +1 (800)500-8486
                        </li>
                        <li>
                            <i block="material-icons">mail</i>
                            Hi@VapeWholesaleUSA.com
                        </li>
                    </div>
                    { COLUMN_MAP.map(this.renderColumn.bind(this)) }
                    <div block="div4">
                        <span block="headline">GET THE LATEST NEWS & OFFERS!</span>
                        <NewsletterSubscription key="NewsletterSubscription"/>
                    </div>
                    <div block="div5">
                        Disclaimer:
                        { /* eslint-disable-next-line max-len */}
                        Not for Sale for Minors - Products sold on this site may contain nicotine which is a highly
                        addictive substance. California Proposition 65 - WARNING: This product can expose you to
                        chemicals including nicotine, which is known to the State of California to cause birth defects
                        or other reproductive harm. For more information, go to Proposition 65 Warnings Website.
                        Products sold on this site is intended for adult smokers. You must be of legal smoking age in
                        your territory to purchase products. Please consult your physician before use. E-Juice on our
                        site may contain Propylene Glycol and/or Vegetable Glycerin, Nicotine and Flavorings. Our
                        products may be poisonous if orally ingested. Products sold by Vape Wholesale USA are not
                        smoking cessation products and have not been evaluated by the Food and Drug Administration, nor
                        are they intended to treat, prevent or cure any disease or condition. For their protection,
                        please keep out of reach of children and pets. Read our terms and conditions page before
                        purchasing our products. Use All Products On This Site At Your Own Risk!
                    </div>
                    <div block="div6">
                        { /* eslint-disable-next-line max-len */}
                        Copyright © 2022 Vape Wholesale USA. All rights reserved.Copyright
                    </div>
                    <div block="div7">
                        <ImageComponent
                            src={media('creditcard_badge_visa.svg', 'icons/')}
                        />
                        <ImageComponent
                            src={media('creditcard_badge_master.svg', 'icons/')}
                        />
                        <ImageComponent
                            src={media('creditcard_badge_discover.svg', 'icons/')}
                        />
                        <ImageComponent
                            src={media('creditcard_badge_american.svg', 'icons/')}
                        />
                    </div>
                </div>
            </ContentWrapper>
        );
    }

    renderContent(): ReactElement {
        const {footer_content: {footer_cms = undefined} = {}} = window.contentConfiguration || {};

        if (footer_cms) {
            return this.renderCmsBlockWrapper();
        }

        return (
            <div block="Footer" elem="Content">
                {this.renderFooter()}
            </div>
        );
    }

    renderCopyrightContent(): ReactElement {
        return (
            <div/>
        );
    }
}

export default FooterComponent;
